import styles from "./ContentDesc.module.css";

import { useState } from "react";
import { pages } from "./examples";

export const ContentDesc = () => {
  
  const [activePage, setActivePage] = useState(Object.keys(pages)[0]);

  function ChangePage(newPage) {
    setActivePage(newPage);
    let pageElement = document.querySelector(`.${styles.page}`);
    if (pageElement) {
      pageElement.scrollIntoView({behavior: 'smooth'});
    }
  }

  return (
    <div className={styles.content}>
      <h2 className={styles.title}>Какво ще откриете в тази книга?</h2>
      <p className={styles.description}>
        В нея има 867 авторски кратки сентенции на всякакви теми от живота.
        Основно се преплитат глупост, мъдрост, живот, смърт, здраве, болести,
        пороци, страх, смелост, щастие, приятелство и всичко каквото ме е
        вълнувало към конкретния момент. Каква е идеята на книгата? Надявам се
        чрез нея да си помогнете за вашите проблеми и намерите вдъхновение и
        сили да се преборите с тях. Да си откриете нещо ценно в нея и в трудни
        моменти да може да ви даде сила и кураж. Също така, когато сте
        изнервени, потиснати или тъжни, четейки от нея да се успокоите и
        погледнете по-позитивно на живота. Доста амбициозна цел наистина, но
        искрено се надявам да ви е от помощ в трудни моменти и полезна като
        цяло.
      </p>


      <h2 className={styles.title}>Примерни страници от книгата:</h2>

      <div className={styles.carousel}>
        <div className={styles.page}>
          <div className={styles.pageExample}>
            <p className={styles.pageNumber}>Страница {activePage}</p>
            {Object.keys(pages[activePage]).map(function(s) {
              const text = pages[activePage][s];
              return(
                <div key={s}>
                  <p className={styles.number}>{s}</p>
                  <p>{text}</p>  
                </div>
              )
            })}
          </div>         
        </div>
      </div>

      <div className={styles.paginationContainer}>
        <div className={styles.pagination}>
          {Object.keys(pages).map(function(p){
            return(
              <div className={p === activePage ? styles.paginationItemActive : styles.paginationItem} onClick={() => ChangePage(p)} key={p}>
                Страница {p}
              </div>
            )
          })}
        </div>
      </div>
      <br />

    </div>
  );
};
