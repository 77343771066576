import styles from './About.module.css';

export const About = () => {
  return (
    <div className={styles.bigContainer}>
        <div className={styles.mainContainer}>
            <div className={styles.imageContainer}>
                <img className={styles.image} src="/images/Cards Sticker - NEW.png" alt="main png" loading="lazy"/>
            </div>
            <div className={styles.txtsContainer}>
                <div className={styles.txtContainer}>
                    <h2 className={styles.subTitle}>Съдържание</h2>
                    <p className={styles.desc}>Това са сто различни кратки сентенции, 
                        събрани в петдесет черно-бели карти,
                        чиято цел е да Ви накарат да намразите цигарите и навика на пушене.</p>
                </div>
                <div className={styles.txtContainer}>
                    <h2 className={styles.subTitle}>Как да ги използвам</h2>
                    <p className={styles.desc}>Всеки път, когато Ви се допуши, вместо
                        цигарата хванете картите и четете от тях,
                        докато отмине порива. Те ще Ви замислят дали да запалите.</p>
                </div>
                <div className={styles.txtContainer}>
                    <h2 className={styles.subTitle}>Предупреждение!</h2>
                    <p className={styles.desc}>Това не са вдъхновяващи цитати, а такива, които да Ви замисляват върху негативните последствия от вредния навик.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
