import styles from './About.module.css';
import { HashLink } from 'react-router-hash-link';

export const About = () => {
    return(
        <div className={styles.container}>
            <div className={styles.btnContainer}>
                <HashLink smooth to="/madrosti-ot-pepelta/#bonus" className={styles.order}>ПЛЮС ПОДАРЪК</HashLink>
            </div>
            <div className={styles.aboutContainer}>
                <h2 className={styles.title}>ЗА КНИГАТА</h2>
                <div className={styles.about}>
                    <div className={styles.aboutDescContainer}>
                        <p className={styles.aboutDesc}>
                            "Мъдрости от пепелта" е моят личен прочит на живота, изразен чрез нестандартни, кратки сентенции и мисли. Това е своеобразно пътешествие през предизвикателствата, които съм минал. Всяка страница е пропита с уроците и поуките, които съм научил по възможно най-трудния начин. Открийте вдъхновяващи истини, които могат да бъдат родени, единствено от силни емоции и преживявания.
                            <br />
                            Като непознат автор, разбирам вашите съмнения за стойността на творбата, затова преценете сами с примерните страници предоставени по-отдолу.
                        </p>
                    </div>
                    <div className={styles.aboutImgContainer}>
                        <img className={styles.aboutImg} src="/images/Wisdom-From-The-Ashes/Back.png" alt="aboutImg" loading="lazy" />
                    </div>
                </div>
            </div>
        </div>
    )
}