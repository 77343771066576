import styles from './Landing.module.css';

export const Landing = () =>{
    return(
        <header className={styles.header}>
            <div className={styles.descContainer}>
                <h1 className={styles.title}>МЪДРОСТИ ОТ ПЕПЕЛТА</h1>
                <p className={styles.desc}>Когато времето си от половин век на тази земя си превърнал в изгаряща болка, накрая се чувстваш като пепел, която има какво да каже на другите, за да не се превърнат във въглени.</p>
                <div className={styles.subtitleContainer}>
                    <p className={styles.subtitle}>Красимир Костадинов</p>
                </div>
            </div>
            <div className={styles.mainImageContainer}>
                <img className={styles.mainImage} src="/images/Wisdom-From-The-Ashes/book-cover-main.png" alt="Madrosti Ot Pepelta" />
            </div>
        </header>
    )
}