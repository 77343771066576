import styles from './Home.module.css';
import { Link } from 'react-router-dom';

export const Home = () => {
  return (
    <>
      <div className={styles.bigContainer}>
        <Link className={styles.smallContainer} to='/madrosti-ot-pepelta'>
          <div className={styles.bookImgContainer}>
            <img className={styles.img} src="/images/Wisdom-From-The-Ashes/SpreadImage.png" alt="" />
          </div>
          <div className={styles.textContainer}>
            <h2 className={styles.bookTitle}>МЪДРОСТИ <br/> ОТ ПЕПЕЛТА</h2>
            <p className={styles.bookDesc}>Сборник с кратки поучителни сентенции за живота.</p>
            <div className={styles.buttonContainer}>
              <Link className={styles.bookButton} to='/madrosti-ot-pepelta'>НАУЧИ ПОВЕЧЕ</Link>
            </div>
          </div>
        </Link>
        {/* <Link className={styles.smallContainer} to='/antistres-kutiya'> */}
        <div className={styles.smallContainer}>
          <div className={styles.therapyImgContainer}>
            <img className={styles.img} src="/images/Antistress-therapy/Box-front.png" alt="" />
          </div>
          <div className={styles.textContainer}>
            <h2 className={styles.therapyTitle}>АНТИСТРЕС КУТИЯ</h2>
            <p className={styles.therapyDesc}>Антистрес дневник и водена терапия в листи за помощ в трудни моменти.</p>
            <div className={styles.buttonContainer}>
              {/* <Link className={styles.therapyButton} to='/antistres-kutiya'>НАУЧИ ПОВЕЧЕ</Link> */}
              <button className={styles.therapyButton}>ОЧАКВАЙТЕ СКОРО</button>
            </div>
          </div>
        </div>
        {/* </Link> */}
        <Link className={styles.smallContainer} to='/motivacionni-karti'>
          <div className={styles.cardsImgContainer}>
            <img className={styles.img} src="/images/Antismoking-cards/Background card box - NEW.png" alt="" />
          </div>
          <div className={styles.textContainer}>
            <h2 className={styles.cardsTitle}>МОТИВАЦИОННИ <br/> КАРТИ</h2>
            <p className={styles.cardsDesc}> 50 карти с мотивационни сентенции, които да ти помогнат да спреш пушенето.</p>
            <div className={styles.buttonContainer}>
              <Link className={styles.cardsButton} to='/motivacionni-karti'>НАУЧИ ПОВЕЧЕ</Link>
            </div>
          </div>
        </Link>
      </div>
    </>
  )
}