import styles from './Bonus.module.css';

export const Bonus = () => {
  return (
    <div id='bonus' className={styles.container}>
        <h2 className={styles.title}>БОНУС - 22 АНТИСТРЕС ТЕХНИКИ</h2>
        <div className={styles.about}>
            <div className={styles.aboutImgContainer}>
                <img className={styles.aboutImg} src="/images/Antismoking-cards/22-cards.webp" alt="bonusImg" loading="lazy" />
            </div>
            <div className={styles.aboutDescContainer}>
                <div className={styles.aboutDesc}>
                    <ul className={styles.list}>
                        <li>
                            Сега имате изключителната възможнст да придобиете тестето карти заедно с двадесет и две изпитани техники за облекчаване и намаляне на стреса. Ще ги получите под формата на PDF файл който ще ви бъде изпратен на имейла Ви след направена поръчка.                        </li>
                        <li>
                            Това е списък с най-добрите средства за намаляне на стреса в ежедневието Ви.
                        </li>
                        <li>
                            Някои от тях са изработени от самите нас, и които най-вероятно няма да сте срещали преди, а други са известни и доказани във времето.
                        </li>
                        <li>
                            Съветвам Ви да изпробвате всички и да изберете това, което работи най-добре за Вас.
                        </li>
                        <li>
                            Прекомерния стрес буквално ни убива, затова се борете с него. Тези техники са оръжие в ръцете Ви, с което да му се противопоставите.
                        </li>
                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}